import React from "react";
import InnerBanner from "./../InnerBanner"
import "./About.css";

const About = (props) => {
    return (
        <React.Fragment>
            <InnerBanner data="About Us" />
            <div className="container">
                <div className="about-main">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Kalinga Testing Instruments was founded in 2013. 
                                We has been leading the market for products distributor and supplier of scientific and laboratory equipment used in medical industry, paint and coatings industry, plastic industry, yarn industry and many others industries. 
                                Our focus remains deeply rooted in building strong relationship with our clients and business partners. We are ISO 9001:2015 certified company, that demonstrate our commitment to provide quality products and professional services to all. 
                                Our logo is created using three primary colors of nature where each color represents a different aspect of nature & also describes the company long term vision in its own way.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="kti-dotted-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="card-header-image"><img src="./images/GO-RED.png" alt="GO RED – Our Vision" /> </div>
                                    <h4 className="card-header txt-red"> GO RED – Our Vision </h4>
                                    <p className="card-text">We wanted to establish our name in the field of Exclusive Dealers for Sales & branches throughout the world in every possible field of research & development. We believe in GO RED & make you believe in us.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="card-header-image"><img src="./images/GO-GREEN.png" alt="GO GREEN - Our Environment " /> </div>
                                    <h4 className="card-header txt-green"> GO GREEN - Our Environment </h4>
                                    <p className="card-text">We are dealing Environment free testing instruments. We not take any steps to pollute our Environment in any aspects. We belive in GO GREEN, KEEP CLEAN, SAVE THE WORLD and make it a better place for living.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="card-header-image"><img src="./images/GO-BLUE.png" alt="GO BLUE - Our Commitment" /> </div>
                                    <h4 className="card-header txt-blue">GO BLUE - Our Commitment</h4>
                                    <p className="card-text">We are committed to provide our integrated & intensive support & services to our valued Customers. We belive in GO BLUE, HELP US TO HELP YOU with providing a world class service & support.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="about-main">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Meet the team </h2>
                            </div>
                            <p>Our team collaborates to protect and grow our business success with their dynamic skills within their respective market disciplines that allows us to offer a wide range of industrial testing and inspection instruments as per the international standard. We believe in a healthy business relationship with our clients through our services.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="kti-dotted-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body our-team">
                                    <div className="card-header-image"><img src="./images/teams/team3.png" alt="MR. UMESH BEHERA" /> </div>
                                    <h4 className="card-header txt-grey"> MR. UMESH BEHERA</h4>
                                    <span className="post txt-grey">MANAGING DIRECTOR</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body our-team">
                                    <div className="card-header-image"><img src="./images/teams/team5.png" alt="MR. SANJAY ROUT" /> </div>
                                    <h4 className="card-header txt-grey"> MR. SANJAY ROUT</h4>
                                    <span className="post txt-grey">QUALITY CONTROL HEAD</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body our-team">
                                    <div className="card-header-image"><img src="./images/teams/team7.png" alt="MRS. SONALI CHOUDHARY" /> </div>
                                    <h4 className="card-header txt-grey">MRS. SONALI CHOUDHARY</h4>
                                    <span className="post txt-grey">HUMAN RESOURCES HEAD</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body our-team">
                                    <div className="card-header-image"><img src="./images/teams/team8.png" alt="MR. PANKAJ KUMAR" /> </div>
                                    <h4 className="card-header txt-grey">MR. PANKAJ KUMAR</h4>
                                    <span className="post txt-grey">IT AND TECHNOLOGY CONSULTANT</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body our-team">
                                    <div className="card-header-image"><img src="./images/teams/team1-sm.png" alt="MRS. SUDHA SWAMY" /> </div>
                                    <h4 className="card-header txt-grey">MRS. SUDHA SWAMY</h4>
                                    <span className="post txt-grey">LEGAL ADVISOR</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body our-team">
                                    <div className="card-header-image"><img src="./images/teams/team9.png" alt="MR. JAIPAL SINGH" /> </div>
                                    <h4 className="card-header txt-grey">MR. JAIPAL SINGH</h4>
                                    <span className="post txt-grey">LEGAL ADVISOR</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default About;