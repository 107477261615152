import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Card from "./../Card/Card"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";


function Home() {
    const featureProducts = useSelector(state => state.Products)
    const clientsData = useSelector(state => state.Client)
    const productCategory = useSelector(state => state.ProductCategory)
    const [carouselProductSetting, setCarouselProductSetting] = useState({ autoplay: true, dots: true, infinite: true, speed: 500, slidesToShow: 3, slidesToScroll: 3 })
    const [carouselCustomerSetting, setCarouselCustomerSetting] = useState({ autoplay: true, dots: true, infinite: true, speed: 500, slidesToShow: 4, slidesToScroll: 4 })

    useEffect(() => {
        if (window.matchMedia('screen and (max-width: 768px)').matches) {
            setCarouselProductSetting({ autoplay: true, dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1 });
            setCarouselCustomerSetting({ autoplay: true, infinite: true, speed: 500, slidesToShow: 2, slidesToScroll: 2 });
        }
    }, []);

    return (
        <React.Fragment>
            <header className="slider-main">
                <div className="carousel slide carousel-fade" data-ride="carousel">
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active" style={{ backgroundImage: 'url("/images/banner-1.jpg")' }}>
                            <div className="carousel-caption d-md-block">
                                <h3>Manufacturer & Distributor of Lab Instruments</h3>
                                <p>Kalinga Testing Instruments is committed to provide quality instruments and services at competitive
                                pricing, to meet individual customer needs within the scientific and engineering communities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="wrapper-main">
                <div className="container py-4">
                    <div className="row py-4">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Our Featured Products</h2>
                            </div>
                        </div>
                        {
                            featureProducts.products.map((product) =>
                                <Card data={product} key={product.sku} />
                            )
                        }
                    </div>
                </div>

                <div className="container">
                    <div className="row py-4">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Our Exclusive Product Range </h2>
                            </div>
                            <Slider {...carouselProductSetting}>
                                {
                                    productCategory.category.map((category) =>
                                        <div key={category.id}>
                                            <div className="post-slide">
                                                <div className="pic">
                                                    <img src={"/images/category/" + category.image} alt={category.name} />
                                                    <ul className="post-category">
                                                        <li>
                                                            <Link to="#">{category.name} </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p className="post-description">{category.description} </p>
                                            </div>
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="kti-dotted-bar">
                    <div className="container">
                        <div className="row py-4">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>Our Services </h2>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="card h-100">
                                    <div className="card-img">
                                        <img className="img-fluid" src="/images/service/calibration-services.jpeg" alt="Calibilration Services" />
                                    </div>
                                    <div className="card-body">
                                        <h4 className="card-header"> Calibilration Services </h4>
                                        <p className="card-text">Getting your instrument calibrated by us gives you the reassurance it is done by an ISO 17025
                                            accredited laboratory.
                                </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="card h-100">
                                    <div className="card-img">
                                        <img className="img-fluid" src="/images/service/amc-services.jpg" alt="Annual Maintenance Contract" />
                                    </div>
                                    <div className="card-body">
                                        <h4 className="card-header"> Annual Maintenance Contract </h4>
                                        <p className="card-text">We provide comprehensive annual maintenance services for all KTI instruments, as well as
                                            brands distributed by KTI.
                                </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="card h-100">
                                    <div className="card-img">
                                        <img className="img-fluid" src="/images/service/product-helpline.jpg" alt="Product Helpline" />
                                    </div>
                                    <div className="card-body">
                                        <h4 className="card-header"> Product Helpline </h4>
                                        <p className="card-text">We offer free technical support for all our clients. Our team are on hand to help you select,
                                            set-up and use all of our products.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Our Happy Customers</h2>
                            </div>
                            <Slider {...carouselCustomerSetting}>
                                {
                                    clientsData.clients.map((client) =>
                                        <div className="mb-4" key={client.name}>
                                            <img className="img-fluid" src={"/images/clients/" + client.image} alt={client.name} />
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="touch-line">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                Kalinga Testing Instruments is proud to announce being ISO 9001:2015 certified recently and would like to thank you for your believe in us and our work. 
                                Our decision to become ISO 9001:2015 certified, demonstrates a commitment to provide quality instruments and services to our customers.
                               </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Home;