import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductDetail.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


function ProductDetail(props) {
    const [singleProductDetail, setSingleProductDetail] = useState({});
    const featureProducts = useSelector(state => state.Products)

    useEffect(() => {
        featureProducts.products.filter(item => {
            return item.slug === props.match.params.slug ? setSingleProductDetail(item) : ""
        })
    }, [props.match.params.slug, featureProducts.products])

    return (
        <React.Fragment>
            <div className="full-title">
                <div className="container">
                    <h1 className="mt-4 mb-3"> {singleProductDetail.title}</h1>
                </div>
            </div>

            <div className="container">
                <div className="product-detail">
                    <div className="row">
                        <div className="col-lg-6">
                            <img className="img-fluid rounded mb-4" src={"/images/products/" + singleProductDetail.brand + "/" + singleProductDetail.image} alt={singleProductDetail.title} />
                        </div>
                        <div className="col-lg-6 left-ab">
                            <div class="display-4 txt-black "><h3>{singleProductDetail.title}</h3></div>
                            <div class="display-4"><p>Product code: {singleProductDetail.sku}</p></div>
                            <div class="display-4 txt-black ">&#8377; {singleProductDetail.price}</div>
                            {/* <div class="display-4">
                                <button type="submit" class="btn btn-primary" id="addToCart">Add To Cart</button>
                            </div> */}
                        </div>
                            <Tabs>
                                <TabList>
                                    <Tab>Description</Tab>
                                    <Tab>Detail</Tab>
                                </TabList>

                                <TabPanel>
                                    <p>{singleProductDetail.description}</p>
                                </TabPanel>
                                <TabPanel>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="header">Country of Manufacture</div>
                                            {singleProductDetail.countryOfManufacture}
                                        </li>
                                        <li class="list-group-item">
                                            <div class="header">Manufacturer</div>
                                            {singleProductDetail.brandDisplay}
                                        </li>
                                        <li class="list-group-item">
                                            <div class="header">Catgeory</div>
                                            {singleProductDetail.category}
                                        </li>
                                        <li class="list-group-item">
                                            <div class="header">keywords</div>
                                            {singleProductDetail.keywords}
                                        </li>
                                    </ul>
                                </TabPanel>
                            </Tabs>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ProductDetail;