import {
    createStore,
    applyMiddleware
} from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers"

const middleware = applyMiddleware(thunkMiddleware);
let Store = createStore(rootReducer, middleware);

export default Store;