import React from "react";
import { Link } from "react-router-dom"

const AppFooter = () => {

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container bottom_border">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col">
              <h5 className="headin5_amrc col_white_amrc pt2">ABOUT KTI</h5>
              <p className="mb10">Kalinga Testing Instruments (KTI) is an ISO 9001:2015 certified company & has been leading the market for products distributor and supplier of scientific & laboratory equipments used in medical industry, paint and coatings industry, plastic industry, yarn industry and many others industries.</p>
              {/* <ul className="footer-social">
                <li>
                  <Link className="facebook hb-xs-margin" to="https://www.facebook.com/ktinst/">
                    <span className="hb hb-xs spin hb-facebook">
                      <i className="fab fa-facebook-f"></i>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link className="twitter hb-xs-margin" to="https://twitter.com/ktinst">
                    <span className="hb hb-xs spin hb-twitter">
                      <i className="fab fa-twitter"></i>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link className="instagram hb-xs-margin" to="#">
                    <span className="hb hb-xs spin hb-instagram">
                      <i className="fab fa-instagram"></i>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link className="googleplus hb-xs-margin" to="#">
                    <span className="hb hb-xs spin hb-google-plus">
                      <i className="fab fa-google-plus-g"></i>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link className="dribbble hb-xs-margin" to="#">
                    <span className="hb hb-xs spin hb-dribbble">
                      <i className="fab fa-dribbble"></i>
                    </span>
                  </Link>
                </li>
              </ul> */}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h5 className="headin5_amrc col_white_amrc pt2">HELPFUL INFORMATION</h5>
              <ul className="footer_ul_amrc">
                <li>
                  <Link to="/about">
                    <i className="fas fa-long-arrow-alt-right"></i>About Us</Link>
                </li>
                <li>
                  <Link to="/services">
                    <i className="fas fa-long-arrow-alt-right"></i>Services</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>Brands</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>News</Link>
                </li>
                <li>
                  <Link to="/contact">
                    <i className="fas fa-long-arrow-alt-right"></i>Contact</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>Help & Technical Support</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col">
              <h5 className="headin5_amrc col_white_amrc pt2">POLICIES</h5>
              <ul className="footer_ul_amrc">
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>Privacy</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>Terms Of Use</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>Terms Of Payment</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>Warranties</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>Delivery</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fas fa-long-arrow-alt-right"></i>Returns</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <div className="news-box">
                <h5 className="headin5_amrc col_white_amrc pt2">Newsletter</h5>
                <p>Sign up for our newsletter to receive special offers, news and valuable information.</p>
                <form action="#">
                  <div className="input-group">
                    <input className="form-control" placeholder="Email Address" type="text" />
                    <span className="input-group-btn">
                      <button className="btn btn-secondary" type="button">Subscribe!</button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="copyright text-center">
            Copyright &copy;  2013-2020 Kalinga Testing Instruments. All Rights Reserved
          </p>
        </div>
      </footer>
    </React.Fragment >
  )
}

export default AppFooter;
