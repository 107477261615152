import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
    const {title, sku, brand, image, keywords, slug } = props.data;
    return (
        <div className="col-lg-4 col-sm-6 portfolio-item" key={sku}>
            <div className="card h-100">
                <Link className="hover-box" to={"product/detail/"+slug}>
                    <div className="dot-full">
                        <i className="fas fa-link"></i>
                    </div>
                    <img className="card-img-top" src={"/images/products/"+brand+"/"+image} alt={title} />
                </Link>
                <div className="card-body">
                    <h4>
                        <Link to={"product/detail/"+slug}>{title} </Link>
                    </h4>
                    <p>{keywords}</p>
                </div>
            </div>
        </div>
    )
}
export default Card;