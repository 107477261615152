import React, { useState } from "react";
import InnerBanner from "./../InnerBanner"
import axios from 'axios';
import './Contact.css'

const Contact = () => {
	const [firstName, setFirstName] = useState(null)
	const [lastName, setLastName] = useState(null)
	const [emailAddress, setEmailAddress] = useState(null)
	const [phoneNumber, setPhoneNumber] = useState(null)
	const [company, setCompany] = useState(null)
	const [enquiry, setEnquiry] = useState(null)
	const [successMessage, setSuccessMessage] = useState(false)
	const [formError, setFormError] = useState({
		firstName: false,
		lastName: false,
		emailAddress: false,
		phoneNumber: false,
		company: false,
		enquiry: false
	})

	const validateEmail = email => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}
	const validateFormFields = () => {
		let error = false;
		let firstNameError = false;
		let lastNameError = false;
		let emailAddressError = false;
		let phoneNumberError = false;
		let companyError = false;
		let enquiryError = false;

		if (firstName === null || firstName === "") {
			error = true;
			firstNameError = true
		}
		if (lastName === null || lastName === "") {
			error = true;
			lastNameError = true;
		}
		if (!validateEmail(emailAddress)) {
			error = true;
			emailAddressError = true;
		}
		if (phoneNumber === null || phoneNumber === "") {
			error = true;
			phoneNumberError = true;
		}
		if (company === null || company === "") {
			error = true;
			companyError = true;
		}
		if (enquiry === null || enquiry === "") {
			error = true;
			enquiryError = true;
		}
		setFormError({
			firstName: firstNameError,
			lastName: lastNameError,
			emailAddress: emailAddressError,
			phoneNumber: phoneNumberError,
			company: companyError,
			enquiry: enquiryError
		});
		return error;
	}

	const handleSubmit = () => {
		setFormError({})
		if (!validateFormFields()) {
			const payload = {
				firstName: firstName,
				lastName: lastName,
				emailAddress: emailAddress,
				phoneNumber: phoneNumber,
				company: company,
				enquiry: enquiry
			}
			axios({
				method: "POST",
				url: "http://ktinst.com/api/v1/message.php",
				data: payload
			}).then(response => {
				setSuccessMessage(true)
				setTimeout(function () {
					setSuccessMessage(false)
				}, 10000);
			})
		}
	}

	return (
		<React.Fragment>
			<InnerBanner data="Contact Us" />
			<div className="kti-dotted-bar">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 mb-4">
							<div className="card h-100">
								<div className="card-body">
									<h4 className="card-header"> Office Address </h4>
									<p className="card-text">
										Shop No 1, Plot No-52, Sector-14, New Kukshet, Nerul West, Mumbai,<br /> Maharashtra 400706</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 mb-4">
							<div className="card h-100">
								<div className="card-body">
									<h4 className="card-header"> Need Help? </h4>
									<p className="card-text">Call us at (022) 2771-6490 to speak with our sales representative to address your queries between 10:00 AM to 6:30 PM</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 mb-4">
							<div className="card h-100">
								<div className="card-body">
									<h4 className="card-header"> Need Support?</h4>
									<p className="card-text">Email us at info@ktinst.com with your questions or suggestions. Our friendly support staff are here for you 24 hours. a day.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="contact-main">
				<div className="container">
					{successMessage ?
						<div className="row">
							<div className="col-lg-12 mb-6" style={{ textAlign: 'center' }}>
								<img src="./images/thank-you.png" alt="Thank you for contacting" />
								<h6>We have received your enquiry and will respond to you within 24 hours.  For urgent enquiries please call us on (022) 2771-6490.</h6>
							</div>
						</div>
						:
						<div className="row">
							<div className="col-lg-12 mb-6">
								<h6>Have any enquiries or would like to request for further information regarding our products and services, please fill below form today!</h6>
							</div>
							<div className="col-lg-6 mb-6">
								<div className="control-group form-group">
									<div className="controls">
										<input type="text" placeholder="First Name" className={formError.firstName ? "form-control form-error" : "form-control"} name="firstName" onChange={e => setFirstName(e.target.value)} />
										<p className="help-block"></p>
									</div>
								</div>
							</div>
							<div className="col-lg-6 mb-6">
								<div className="control-group form-group">
									<div className="controls">
										<input type="text" placeholder="Last Name" className={formError.lastName ? "form-control form-error" : "form-control"} onChange={e => setLastName(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="col-lg-6 mb-6">
								<div className="control-group form-group">
									<div className="controls">
										<input type="email" placeholder="Email Address" className={formError.emailAddress ? "form-control form-error" : "form-control"} onChange={e => setEmailAddress(e.target.value)} />
										<p className="help-block"></p>
									</div>
								</div>
							</div>
							<div className="col-lg-6 mb-6">
								<div className="control-group form-group">
									<div className="controls">
										<input type="text" placeholder="Phone Number" className={formError.phoneNumber ? "form-control form-error" : "form-control"} onChange={e => setPhoneNumber(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="col-lg-12 mb-6">
								<div className="control-group form-group">
									<div className="controls">
										<input type="text" placeholder="Company" className={formError.company ? "form-control form-error" : "form-control"} onChange={e => setCompany(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="col-lg-12 mb-6">
								<div className="control-group form-group">
									<div className="controls">
										<textarea rows="5" cols="100" placeholder="Enquiry" className={formError.enquiry ? "form-control form-error" : "form-control"} onChange={e => setEnquiry(e.target.value)}></textarea>
									</div>
								</div>
							</div>
							<div className="col-lg-12 mb-6">
								<button type="submit" className="btn btn-primary" onClick={() => handleSubmit()}>Submit</button>
							</div>
						</div>
					}
				</div>
			</div>

			<div className="map-main">
				<iframe title="kti-map" style={{width:"100%", height:"300px", frameBorder:"0", scrolling:"no", marginHeight:"0", marginWidth:"0"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30172.298041181526!2d73.011251!3d19.040102!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x46c4cf7815b36887!2sKalinga%20Testing%20Instruments%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1590438702984!5m2!1sen!2sin" aria-hidden="false"></iframe>
			</div>
		</React.Fragment >
	)
}

export default Contact;
