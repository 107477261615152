import React from "react";

const InnerBanner = (props) => {
    return (
        <React.Fragment>
            <div className="full-title">
                <div className="container">
                    <h1 className="mt-4 mb-3"> {props.data}</h1>
                </div>
            </div>
        </React.Fragment>
    )
}
export default InnerBanner;