import React from "react";
import { Link } from 'react-router-dom';
import InnerBanner from "./../InnerBanner"

const NotFoundRoute = () => {
    return (
        <React.Fragment>
            <InnerBanner data="Oops, Something went wrong !!" />
            <div className="container">
                <div className="error-contents">
                    <div className="error-img">
                        <img className="img-fluid" src="images/404.png" alt="" />
                    </div>
                    <p>Sometime server not able to accomodate anymore request. Please visit again after sometime. We apologize for any inconvenience. If you don't see what your looking for, or need assistance, Please feel free to call us at (022) 2771-6490 or just drop an email at info@ktinst.com with your questions / comments.</p>
                    <Link className="btn btn-primary" to="/"> Back To Homepage </Link>
                </div>
            </div>
        </React.Fragment>
    )
}
export default NotFoundRoute;