import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './Header.css';

const AppHeader = () => {
	const [enableNavBar, setNavBar] = useState("");
	const [activeNav, setActiveNav] = useState("/")

	useEffect(() => {
		setNavBar("");
		setActiveNav(window.location.pathname)
	}, [])

	return (
		<React.Fragment> <div className="top-bar">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="social-media">
							<ul>
								<li>
									<a href="https://www.facebook.com/ktinst/" target="_new">
										<i className="fab fa-facebook-f"></i>
									</a>
								</li>
								<li>
									<a href="https://twitter.com/ktinst" target="_new">
										<i className="fab fa-twitter"></i>
									</a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/ktinst/" target="_new">
										<i className="fab fa-linkedin-in"></i>
									</a>
								</li>
								{/* <li>
									<Link to="#">
										<i className="fab fa-instagram"></i>
									</Link>
								</li>
								<li>
									<Link to="#">
										<i className="fab fa-google-plus-g"></i>
									</Link>
								</li> */}
							</ul>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="contact-details">
							<ul>
								<li>
									<i className="fas fa-phone fa-rotate-90"></i> (022) 2771-6490  </li>
								<li><i className="fas fa-map-marker-alt"></i> Nerul West, Mumbai</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
			<nav className="navbar navbar-expand-lg navbar-dark bg-light top-nav">
				<div className="container">
					<Link className="navbar-brand" to="/">
						<img src="/images/logo.png" alt="logo" />
					</Link>

					<button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" onClick={() => { enableNavBar ? setNavBar("") : setNavBar("show") }}
						aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
						<span className="fas fa-bars"></span>
					</button>
					<div className={"collapse navbar-collapse " + enableNavBar} >
						<ul className="navbar-nav ml-auto">
							<li className="nav-item">
								<Link className={activeNav === "/" ? "nav-link active" : "nav-link"} to="/" onClick={() => setActiveNav('/')}>Home</Link>
							</li>
							<li className="nav-item">
								<Link className={activeNav === "/about" ? "nav-link active" : "nav-link"} to="/about" onClick={() => setActiveNav('/about')}>About</Link>
							</li>
							<li className="nav-item">
								<Link className={activeNav === "/services" ? "nav-link active" : "nav-link"} to="/services" onClick={() => setActiveNav('/services')}>Services</Link>
							</li>
							<li className="nav-item">
								<Link className={activeNav === "/contact" ? "nav-link active" : "nav-link"} to="/contact" onClick={() => setActiveNav('/contact')}>Contact</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</React.Fragment >
	)
}

export default AppHeader;