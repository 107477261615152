import {
    combineReducers
} from "redux";
import Quotation from "./Quotation"
import Products from "./Products"
import Client from "./Client"
import ProductCategory from "./ProductCategory"

const rootReducer = combineReducers({
    Quotation
    ,Products
    ,Client
    ,ProductCategory
});

export default rootReducer;