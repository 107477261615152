const initalState = {
    items: [
        {
            label: 'Options',
            items: [{label: 'Upload', icon: 'pi pi-fw pi-upload', command:()=>{ window.location.hash="/fileupload"; }},
                    {label: 'Home', icon: 'pi pi-fw pi-home', url: 'http://primetek.com.tr'}]
        },
        {
            label: 'Account',
            items: [{label: 'Components', icon: 'pi pi-fw pi-cog', command:()=>{ window.location.hash="/"; }},
                    {label: 'Sign Out', icon: 'pi pi-fw pi-power-off'} ]
        }
    ]
}

export default (state = initalState, action) => {
    switch (action.type) {
        case "UPDATE_QUOTATION":
            return {
                ...state,
                "qtn": "222222",
                "name": "Sudhir"
            };
        default:
            return state;
    }
};