
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react";
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Home from './components/Home';
import AppHeader from "./components/Header";
import AppFooter from "./components/Footer";
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import Store from "./store"
import ProductDetail from "./components/ProductDetail"
import Contact from "./components/Contact"
import About from "./components/About"
import Service from "./components/Service"
import NotFoundRoute from "./components/NotFoundRoute"
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

const customHistory = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Router history={customHistory}>
          <AppHeader />
          <Switch>
          <Route exact path="/product/detail/:slug" component={ProductDetail} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/services" component={Service} />
          <Route exact path="/about" component={About} />
          <Route exact path="/" component={Home} />
          <Route component={NotFoundRoute} />
          </Switch>
          <AppFooter />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
