const initalState = {
        products: [{
            "id": "wm9100001",
            "sku":"wm9100001",
            "title": "Orion® 910 Deep Depth Pinless Wood Moisture Meter Kit with On-Demand Calibrator Platform",
            "description": "The Orion® 910 Deep Depth Pinless Wood Moisture Meter Kit continues the Wagner tradition of superior accuracy and adds incredible new functionality. The new Orion 910 meter incorporates IntelliSense™ technology, a more rugged design, and industry-exclusive true in-the-field calibration capability provided by the included On-Demand Calibrator.",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "910-kit.jpg",
            "price": "37,520",
            "slug": "orion-910-deep-wm9100001",
            "manual": "orion-910-manual.pdf",
            "keywords": "Wood Moisture Meter, Moisture Meter"
        }, {
            "id": "wm9100002",
            "sku":"wm9100002",
            "title": "Orion® 910 Deep Depth Pinless Wood Moisture Meter Kit With NIST Traceable On-Demand Calibrator Platform",
            "description": "The Orion® 910 Deep Depth Pinless Wood Moisture Meter Kit continues the Wagner tradition of superior accuracy and adds incredible new functionality. The new Orion 910 meter incorporates IntelliSense™ technology, a more rugged design, and industry-exclusive true in-the-field calibration capability provided by the included On-Demand Calibrator.",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "910-kit.jpg",
            "price": "44,950",
            "slug": "orion-910-deep-wm9100002",
            "manual": "orion-910-manual.pdf",
            "keywords": "Wood Moisture Meter, Moisture Meter"
        }, {
            "id": "wm9200001",
            "sku":"wm9200001",
            "title": "Orion® 920 Shallow Depth Pinless Wood Moisture Meter Kit On Demand Calibrator Platform",
            "description": "The Orion 920 Shallow Depth Pinless Wood Moisture Meter Kit features the Orion 920 meter designed exclusively for shallow measurement of moisture content from the surface down to .25” (6.4mm) deep inside wood or other material. Ideal for applications where accurate moisture readings from thin wood pieces or coatings are critical to success.",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "920-kit.jpg",
            "price": "37,520",
            "slug": "orion-920-shallow-wm9200001",
            "manual": "orion-920-manual.pdf",
            "keywords": "Wood Moisture Meter, Moisture Meter"
        }, {
            "id": "wm9200002",
            "sku":"wm9200002",
            "title": "Orion® 920 Shallow Depth Pinless Wood Moisture Meter Kit With NIST Traceable On-Demand Calibrator Platform",
            "description": "The Orion 920 Shallow Depth Pinless Wood Moisture Meter Kit features the Orion 920 meter designed exclusively for shallow measurement of moisture content from the surface down to .25” (6.4mm) deep inside wood or other material. Ideal for applications where accurate moisture readings from thin wood pieces or coatings are critical to success.",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "920-kit.jpg",
            "price": "44,950",
            "slug": "orion-920-shallow-wm9200002",
            "manual": "orion-920-manual.pdf",
            "keywords": "Wood Moisture Meter, Moisture Meter"
        }, {
            "id": "wm9300001",
            "sku": "wm9300001",
            "title": "Orion® 930 Dual Depth Pinless Wood Moisture Meter Kit",
            "description": "The Orion 930 Dual Depth Pinless Wood Moisture Meter Kit features the Orion 930 meter, designed for measurement of moisture content from the surface down to .25” (6.4mm) or .75” (19mm) deep inside wood or other material. The Orion 930 Dual Depth Moisture Meter features the versatility that is needed for flooring, quality control, woodworking, and inspection applications.",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "930-kit.jpg",
            "price": "42,470",
            "slug": "orion-930-dual-wm9300001",
            "manual": "orion-930-manual.pdf",
            "keywords": "Wood Moisture Meter, Moisture Meter"
        }, {
            "id": "wm9300002",
            "sku": "wm9300002",
            "title": "Orion® 930 Dual Depth Pinless Wood Moisture Meter Kit With NIST Traceable On-Demand Calibrator Platform",
            "description": "The Orion 930 Dual Depth Pinless Wood Moisture Meter Kit features the Orion 930 meter, designed for measurement of moisture content from the surface down to .25” (6.4mm) or .75” (19mm) deep inside wood or other material. The Orion 930 Dual Depth Moisture Meter features the versatility that is needed for flooring, quality control, woodworking, and inspection applications.",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "930-kit.jpg",
            "price": "49,400",
            "slug": "orion-930-dual-wm9300002",
            "manual": "orion-930-manual.pdf",
            "keywords": "Wood Moisture Meter, Moisture Meter"
        },
        {
            "id": "wm9400001",
            "sku": "wm9400001",
            "title": "Orion® 940 Data Collection Pinless Wood Moisture Meter Kit",
            "description": "The Orion 940 Data Collection Pinless Wood Moisture Meter Kit features the Orion 940 meter, designed for measurement of moisture content from the surface down to .25” (6.4mm) or .75” (19mm) deep inside wood or other materials. The Orion 940 Moisture Meter also features data collection and storage capabilities vital to Quality Control and home or property restoration applications.            ",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "940-kit.jpg",
            "price": "44,900",
            "slug": "orion-940-data-wm9400001",
            "manual": "orion-940-manual.pdf",
            "keywords": "Surface Wood Moisture Meter (PIN LESS), Wood Moisture Meter, Moisture Meter"
        },
        {
            "id": "wm9400002",
            "sku": "wm9400002",
            "title": "Orion® 940 Data Collection Pinless Wood Moisture Meter Kit With NIST Traceable On-Demand Calibrator Platform",
            "description": "The Orion 940 Data Collection Pinless Wood Moisture Meter Kit features the Orion 940 meter, designed for measurement of moisture content from the surface down to .25” (6.4mm) or .75” (19mm) deep inside wood or other materials. The Orion 940 Moisture Meter also features data collection and storage capabilities vital to Quality Control and home or property restoration applications.            ",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "940-kit.jpg",
            "price": "56,800",
            "slug": "orion-940-data-wm9400002",
            "manual": "orion-940-manual.pdf",
            "keywords": "Surface Wood Moisture Meter (PIN LESS), Wood Moisture Meter, Moisture Meter"
        },
        {
            "id": "wm9500001",
            "sku": "wm9500001",
            "title": "Orion® 950 Smart Pinless Wood Moisture Meter with Internal EMC Calculator and Temperature RH Sensor Kit",
            "description": "The Orion 950 Smart Pinless Wood Moisture Meter Kit is the all-in-one solution for busy professionals desiring superior accuracy and multi-tool functionality. The Orion 950 features an on-board Equilibrium Moisture Content (EMC) calculator, Bluetooth connectivity to free smart device apps, built-in temperature/RH sensor, and more.",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "950-kit.jpg",
            "price": "53,360",
            "slug": "orion-950-data-wm9500001",
            "manual": "orion-950-manual.pdf",
            "keywords": "Surface Wood Moisture Meter (PIN LESS), Wood Moisture Meter, Moisture Meter"
        },
        {
            "id": "wm9500002",
            "sku": "wm9500002",
            "title": "Orion® 950 Smart Pinless Wood Moisture Meter With Internal EMC Calculator And Temperature RH Sensor Kit With NIST Traceable On-Demand Calibrator Platform",
            "description": "The Orion 950 Smart Pinless Wood Moisture Meter Kit is the all-in-one solution for busy professionals desiring superior accuracy and multi-tool functionality. The Orion 950 features an on-board Equilibrium Moisture Content (EMC) calculator, Bluetooth connectivity to free smart device apps, built-in temperature/RH sensor, and more.",
            "category": "Wood Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "950-kit.jpg",
            "price": "60,800",
            "slug": "orion-950-data-wm9400002",
            "manual": "orion-950-manual.pdf",
            "keywords": "Surface Wood Moisture Meter (PIN LESS), Wood Moisture Meter, Moisture Meter"
        },
        {
            "id": "wmc555",
            "sku": "wmc555",
            "title": "C555 Handheld Concrete Moisture Meter Kit",
            "description": "The C555 Concrete Moisture Meter provides fast, non-destructive preliminary moisture measurements on smooth, firm concrete to help pinpoint problem areas and determine where to place Rapid RH® L6 Smart Sensors. With its built-in ambient sensors and included On-Demand Calibrator, the C555 comes with everything you need to perform the test as outlined in ASTM F2659.",
            "category": "Concrete Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "c555-product-kit.jpg",
            "price": "34,500",
            "slug": "c555-handheld-concrete-moisture-meter-kit",
            "manual": "c555-manual-web.pdf",
            "keywords": "Surface Concrete Moisture Meter (PIN LESS), Concrete Moisture Meter, Moisture Meter"
        },
        {
            "id": "wmit0001",
            "sku": "wmit0001",
            "title": "Infrared Thermometer",
            "description": "Ideal for building inspectors as well as finished flooring and coating contractors. The Infrared (IR) Thermometer features Data Hold, an LCD backlit screen, easy Fahrenheit/Celsius switch, 7-second Auto-Off, and Low Battery Indication.",
            "category": "Concrete Moisture Meter",
            "brand": "wagnermeters",
            "brandDisplay": "Wagner Meters",
            "countryOfManufacture": "USA",
            "image": "irtherm.jpg",
            "price": "6,200",
            "slug": "infrared-thermometer-wmit0001",
            "manual": null,
            "keywords": "Concrete Moisture Meter, Moisture Meter, Building Inspection Tools"
        }]
    }

export default (state = initalState, action) => {
    switch (action.type) {
        case "UPDATE_QUOTATION":
            return {
                ...state,
                "qtn": "222222",
                "name": "Sudhir"
            };
        default:
            return state;
    }
};