const initalState = {
        clients: [
            {
                "name": "DRDO",
                "image": "drdo.png"
            }, {
                "name": "Prestige",
                "image": "prestige.png"
            }, {
                "name": "Tata",
                "image": "tata.png"
            }, {
                "name": "Asain Paints",
                "image": "asain-paints.png"
            }, {
                "name": "Hero Moto Corp",
                "image": "hero-moto-corp.png"
            }, {
                "name": "Berger Paints",
                "image": "berger-paints.png"
            }, {
                "name": "Fiat",
                "image": "fiat.png"
            }, {
                "name": "Maruti Suzuki",
                "image": "Maruti-Suzuki.png"
            }]
    }

export default (state = initalState, action) => {
    switch (action.type) {
        case "UPDATE_QUOTATION":
            return {
                ...state,
                "qtn": "222222",
                "name": "Sudhir"
            };
        default:
            return state;
    }
};