const initalState = {
        "category": [{
            "id": "C06",
            "name": "Paint & Coating",
            "description": "Distributor of quality inspection and testing instruments used in the paint & coating industries with our client quality driven services.",
            "image": "Paint-and-Coating.jpg",
            "slug": "paint-instruments"
        }, {
            "id": "C02",
            "name": "Appearance",
            "description": "Distributor of gloss meters, DOI meters and colour assessment equipment. Colour is quantified by the material's RGB values.",
            "image": "apearance.jpg",
            "slug": "appearance-instruments"
        }, {
            "id": "C03",
            "name": "Adhesive",
            "description": "Distributor of adhesive testing instruments for quality control and production testing with our client quality driven services.",
            "image": "adhesive-and-material.jpg",
            "slug": "adhesives-instruments"
        }, {
            "id": "C07",
            "name": "Moisture Meter",
            "description": "Distributor of moisture testing meters to measure moisture in wood, building materials, roofing, insulation, plaster, brick and paper.",
            "image": "moisture-meter.png",
            "slug": "moisture-meter"
        }, {
            "id": "C05",
            "name": "Furnace and Oven",
            "description": "Distributor of heating and drying ovens, furnaces, vacuum ovens and accessories with the best technology to our customers.",
            "image": "furnace-and-Oven.png",
            "slug": "furnace-and-oven"
        }, {
            "id": "C08",
            "name": "Medical Instruments",
            "description": "Distributor of high quality medical instruments and accessories, providing excellent products and services to our customers.",
            "image": "medical-instrument.png",
            "slug": "medical-instruments"
        }]
    }

export default (state = initalState, action) => {
    switch (action.type) {
        case "UPDATE_QUOTATION":
            return {
                ...state,
                "qtn": "222222",
                "name": "Sudhir"
            };
        default:
            return state;
    }
};