import React from "react";
import InnerBanner from "./../InnerBanner"
import "./Service.css";

const Service = (props) => {
    return (
        <React.Fragment>
            <InnerBanner data="Services" />
            <div className="container">
                <div className="about-main">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Calibilration Services</h2>
                            </div>
                            <p>Kalinga Testing Instruments is a reliable service provider in industry for Instrument Calibilration Services. Being a client centric organization, these services are highly customized as per the client's requirement. We offer these services at market leading price to our valued clients. These services are rendered under the guidance of professional staffs.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="about-main">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Annual Maintenance Contract (AMC)</h2>
                            </div>
                            <p>Kalinga Testing Instruments provide comprehensive annual maintenance services for all KTI instruments, as well as brands distributed by KTI. Being a client centric organization, these services are highly customized as per the client's requirement. We offer these services at market leading price. These services are rendered under the guidance of professional staffs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Service;